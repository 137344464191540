import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import Autocomplete from '@trevoreyre/autocomplete-vue'
import VueContentPlaceholders from 'vue-content-placeholders'
import '@trevoreyre/autocomplete-vue/dist/style.css'


Vue.prototype.searchState = window.searchState || 'all'; // min or all

Vue.use(Autocomplete)
Vue.use(VueContentPlaceholders)

new Vue({
  render: h => h(App),
}).$mount('#app')
