<template>
  <div>
    <main class="site-content container">
      <div id="ifc2f7">
        <ul role="tablist" id="iid02" class="zones-nav nav">
          <li class="nav-item">
            <span
              id="bedrijven-tab"
              data-toggle="tab"
              href="#bedrijven"
              role="tab"
              aria-controls="bedrijven"
              aria-selected="false"
              class="nav-link search-cursor-tab active text-uppercase"
              >Bedrijven</span
            >
          </li>
          <li class="nav-item">
            <span
              id="news-tab"
              data-toggle="tab"
              href="#nieuws"
              role="tab"
              aria-controls="news"
              aria-selected="false"
              class="nav-link search-cursor-tab text-uppercase"
              >Nieuws</span
            >
          </li>
          <li class="nav-item">
            <span
              id="events-tab"
              data-toggle="tab"
              href="#events"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
              class="nav-link search-cursor-tab text-uppercase"
              >Events</span
            >
          </li>
          <li class="nav-item">
            <span
              id="jobs-tab"
              data-toggle="tab"
              href="#jobs"
              role="tab"
              aria-controls="jobs"
              aria-selected="true"
              class="nav-link search-cursor-tab text-uppercase"
              >Jobs</span
            >
          </li>
        </ul>
        <div id="isj6n" class="tab-content">
          <div
            id="bedrijven"
            role="tabpanel"
            aria-labelledby="bedrijven"
            class="tab-pane active show"
          >
            <div
              id="i61qo"
              class="search-main-container search-main-container__bedrijven"
            >
              <autocomplete
                :get-result-value="getResultValue"
                :search="search"
                placeholder="Zoek &amp; vind in ZONE Houthalen-Helchteren ..."
                @submit="handleSubmit"
              >
                <template #result="{ result, props }">
                  <li v-bind="props" class="autocomplete-result">
                    <div class="autocomplete-result-item">
                      <img :src="result.favicon || defaultImage.favicon" />
                      {{ result.name }}
                    </div>
                  </li>
                </template>
              </autocomplete>
              <button
                id="iv4rlg"
                class="
                  button
                  search-btn
                  text-uppercase
                  btn btn-primary
                  text-white
                  border-0
                "
                @click="handleSubmit('')"
              >
                Zoeken
              </button>
            </div>
          </div>
          <div
            id="nieuws"
            role="tabpanel"
            aria-labelledby="news-tab"
            class="tab-pane fade"
          >
            <div
              id="i25zpf"
              class="search-main-container search-main-container__nieuws"
            >
              <autocomplete
                :get-result-value="getResultValuePost"
                :search="searchNews"
                placeholder="Zoek &amp; vind nieuws in ZONE Houthalen-Helchteren ..."
                @submit="handleSubmitNews"
              >
                <template #result="{ result, props }">
                  <li v-bind="props" class="autocomplete-result">
                    <div
                      class="
                        autocomplete-result-item autocomplete-result-item-big
                      "
                    >
                      <img :src="renderImageFromBlogPost(result.content.$t)" />
                      <h4>{{ result.title.$t }}</h4>
                    </div>
                    <!-- <div class="wiki-snippet" v-html="result.snippet" /> -->
                  </li>
                </template>
              </autocomplete>
              <button
                id="ioj1o1"
                class="
                  button
                  search-btn
                  text-uppercase
                  btn btn-primary
                  text-white
                  border-0
                "
                @click="handleSubmitNews"
              >
                Zoeken
              </button>
            </div>
          </div>
          <div
            id="events"
            role="tabpanel"
            aria-labelledby="events-tab"
            class="tab-pane fade"
          >
            <div class="search-main-container search-main-container__events">
              <autocomplete
                :get-result-value="getResultValuePost"
                :search="searchEvents"
                placeholder="Zoek &amp; vind events in ZONE Houthalen-Helchteren ..."
                @submit="handleSubmitEvents"
              >
                <template #result="{ result, props }">
                  <li v-bind="props" class="autocomplete-result">
                    <div
                      class="
                        autocomplete-result-item autocomplete-result-item-big
                      "
                    >
                      <img :src="renderImageFromBlogPost(result.content.$t)" />
                      <h4>{{ result.title.$t }}</h4>
                    </div>
                  </li>
                </template>
              </autocomplete>
              <button
                id="iveyni"
                class="
                  button
                  search-btn
                  text-uppercase
                  btn btn-primary
                  text-white
                  border-0
                "
                @click="handleSubmitEvents"
              >
                Zoeken
              </button>
            </div>
          </div>
          <div
            id="jobs"
            role="tabpanel"
            aria-labelledby="jobs-tab"
            class="tab-pane fade"
          >
            <div
              id="izcf7i"
              class="search-main-container search-main-container__jobs"
            >
              <autocomplete
                :get-result-value="getResultValuePost"
                :search="searchJobs"
                placeholder="Zoek &amp; vind jobs in ZONE Houthalen-Helchteren ..."
                @submit="handleSubmitJobs"
              >
                <template #result="{ result, props }">
                  <li v-bind="props" class="autocomplete-result">
                    <div
                      class="
                        autocomplete-result-item autocomplete-result-item-big
                      "
                    >
                      <img :src="renderImageFromBlogPost(result.content.$t)" />
                      <h4>{{ result.title.$t }}</h4>
                    </div>
                  </li>
                </template> </autocomplete
              ><button
                id="i7pbd4"
                class="
                  button
                  search-btn
                  text-uppercase
                  btn btn-primary
                  text-white
                  border-0
                "
                @click="handleSubmitJobs"
              >
                Zoeken
              </button>
            </div>
          </div>
        </div>
        <!-- <div id="ifdzk7" class="mt-4 text-center">
          <div id="ipi1xy">
            <p id="ikrqj3" class="mb-0">
              <span @click="filterBusinesses('Bouw')" class="mr-1 ml-1 text-link">Bouw</span>
              |
              <span @click="filterBusinesses('Chemische producten en kunststoffen')"
                class="mr-1 ml-1 text-link">Chemische producten en kunststoffen</span>
              |
              <span @click="filterBusinesses('Dienstverlening')" class="mr-1 ml-1 text-link">Dienstverlening</span>
              |
              <span @click="filterBusinesses('Drukken en publiceren')" class="mr-1 ml-1 text-link">Drukken
                en publiceren</span>
              |
              <span @click="filterBusinesses('Educatie en organisatie')" class="mr-1 ml-1 text-link">Educatie en
                organisatie</span>
              |
              <span @click="filterBusinesses('Elektronica en optiek')" class="mr-1 ml-1 text-link">Elektronica en
                optiek</span>
              |
              <span @click="filterBusinesses('Energie en milieu')" class="mr-1 ml-1 text-link">Energie en
                milieu</span>
              |
              <span @click="filterBusinesses('Leden')" class="mr-1 ml-1 text-link">Leden</span>
              |
              <span @click="filterBusinesses('Landbouw en voeding')" class="mr-1 ml-1 text-link">Landbouw
                en voeding</span>
              |
              <span @click="filterBusinesses('Metalen machines en techniek')"
                class="mr-1 ml-1 text-link">Metalen/machines en techniek</span>
              |
              <span @click="filterBusinesses('Retail en handel')" class="mr-1 ml-1 text-link">Retail en
                handel</span>
              |
              <span @click="filterBusinesses('Transport en logistiek')" class="mr-1 ml-1 text-link">Transport en
                logistiek</span>
              |
              <span @click="filterBusinesses('Vrije tijd en toerisme')" class="mr-1 ml-1 text-link">Vrije
                tijd en toerisme</span>
            </p>
          </div>
        </div> -->
      </div>

      <section>
        <div
          v-if="
            (type === 'search' &&
              searchLoaded &&
              finalSearchResult.length === 0) ||
            !finalSearchResult
          "
          class="zones-placeholder-container mt-5"
        >
          <h3>Geen resultaat gevonden.</h3>
        </div>
        <div
          v-if="finalSearchResult.length > 0 && type === 'search'"
          class="zones-placeholder-container"
        >
          <div>
            <h2>Resultaten: {{ finalSearchResult.length }}</h2>
            <table class="table table-hover table-striped">
              <thead>
                <th><strong>Name</strong></th>
              </thead>
              <tr v-for="resultItem in finalSearchResult">
                <td class="zones-result-container">
                  <div class="zones-search-result__image">
                    <a :href="`${DOMAIN_URL}bedrijven/${resultItem.slug}`">
                      <img
                        :src="
                          resultItem.logo ||
                          resultItem.favicon ||
                          defaultImage.favicon
                        "
                    /></a>
                  </div>
                  <div class="zones-search-result__description">
                    <h3 class="mb-3">
                      <a :href="`${DOMAIN_URL}bedrijven/${resultItem.slug}`">{{
                        resultItem.name
                      }}</a>
                    </h3>
                    <!-- <span
                      class="badge badge-info-custom"
                      v-if="resultItem.sector"
                      >{{ resultItem.sector }}</span
                    > |  -->
                    <p class="mb-2">
                      <span v-if="resultItem.address">
                        {{ resultItem.address }}</span
                      >
                    </p>
                    <table class="table w-100 p-0">
                      <tr>
                        <td class="pl-0 td-title">Contact nr:</td>
                        <td class="pl-0">
                          <a
                            v-if="resultItem.contactNo"
                            :href="`tel:${resultItem.contactNo}`"
                            >{{ resultItem.contactNo }}</a
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 td-title">E-mail:</td>
                        <td class="pl-0">
                          <a
                            v-if="resultItem.email"
                            :href="`mailto:${resultItem.email}`"
                            >{{ resultItem.email }}</a
                          >
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
          v-if="type === 'search-post'"
          class="zones-placeholder-container mt-5"
        >
          <h3>Geen resultaat gevonden.</h3>

          <div
            v-if="finalSearchResult.length > 0"
            class="zones-placeholder-container"
          >
            <div>
              <h2>Resultaten: {{ finalSearchResult.length }}</h2>
              <table class="table table-hover table-striped">
                <thead>
                  <th><strong>Name</strong></th>
                </thead>
                <tr v-for="resultItem in finalSearchResult">
                  <td class="zones-result-container">
                    <div class="zones-search-result__image">
                      <a :href="readPost(resultItem)">
                        <img
                          :src="
                            renderImageFromBlogPost(resultItem.content.$t) ||
                            defaultImage.favicon
                          "
                      /></a>
                    </div>
                    <div class="zones-search-result__description">
                      <h4 class="mb-3">
                        <a :href="readPost(resultItem)">{{
                          resultItem.title.$t
                        }}</a>
                      </h4>
                      <p class="mb-2">
                        <span v-if="resultItem.address">
                          | {{ resultItem.address }}</span
                        >
                      </p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div v-if="searchState === 'all'">
          <div class="prices mb-5">
            <div
              class="
                flex
                border-b-1 border-gray-light
                headline
                f-align-end
                b--spacer
                f-justify-between
                flex-row
              "
            >
              <h2 class="mb-0 secondary-header">Zones Leden</h2>
            </div>

            <div class="grid zones-staff-pick">
              <div
                v-for="zoneItem in zoneMembers"
                :key="zoneItem.id"
                class="zones-staff-pick__item"
              >
                <a :href="`${DOMAIN_URL}bedrijven/${zoneItem.slug}`"
                  ><img
                    :src="
                      zoneItem.mainImage ||
                      zoneItem.coverImage ||
                      defaultImage.cover
                    "
                  />
                </a>
                <!-- <div class="zones-staff-pick__sector">
                  {{ zoneItem.sector }}
                </div> -->
                <div class="zones-staff-pick__name">
                  <a :href="`${DOMAIN_URL}bedrijven/${zoneItem.slug}`">
                    <h3 class="place-names">{{ zoneItem.name }}</h3>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-5 g-maps">
            <iframe src="https://www.google.com/maps/d/embed?mid=1wavpDOyExLBizqlxYumJ00sgTlZyztz4&ehbc=2E312F" width="100%" height="480"></iframe>
          </div>
      

          <div class="mb-4">
            <div
              class="
                flex
                border-b-1 border-gray-light
                headline
                f-align-end
                b--spacer
                f-justify-between
                flex-row
              "
            >
              <h2 class="mb-0 secondary-header">ZONE Vlaanderen</h2>
            </div>

            <div class="grid grid-explore">
              <div
                class="grid-explore-item"
                v-for="(zonesItem, index) in pageOfItems"
                :key="index"
              >
                <a :href="`${DOMAIN_URL}bedrijven/${zonesItem.slug}`">
                  <img
                    :src="
                      zonesItem.mainImage ||
                      zonesItem.coverImage ||
                      defaultImage.cover
                    "
                /></a>
                <div
                  class="p-4"
                  style="
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                  "
                >
                  <h3 class="place-names">{{ zonesItem.name }}</h3>
                  <a
                    :href="`${DOMAIN_URL}bedrijven/${zonesItem.slug}`"
                    class="btn__custom w-100"
                    >ONTDEK MEER</a
                  >
                </div>
              </div>
            </div>
          </div>

          <Pagination :items="companyLists" @changePage="onChangePage" :pageSize="16" />

          <hr />

          <!-- <div class="flex border-b-1 border-gray-light headline f-align-end b--spacer f-justify-between flex-row">
            <h2 class="mb-0 secondary-header">Zoek op sector</h2>
          </div>
          <div class="zones-sector-container">
            <div v-for="(sector, index) in Object.keys(sectors)" :key="index">
              <div class="zones-sector">
                <a v-if="sectors[sector][0]" :href="`${DOMAIN_URL}bedrijven/${sectors[sector][0].slug}`"
                  class="zones-sector__big"><img class=""
                    :src="sectors[sector][0].mainImage || sectors[sector][0].coverImage || defaultImage.cover" /></a>
                <a v-if="sectors[sector][1]" :href="`${DOMAIN_URL}bedrijven/${sectors[sector][1].slug}`"
                  class="zones-sector__small-top"><img class=""
                  :src="sectors[sector][1].mainImage || sectors[sector][1].coverImage || defaultImage.cover"/></a>
                <a v-if="sectors[sector][2]" :href="`${DOMAIN_URL}bedrijven/${sectors[sector][2].slug}`"
                  class="zones-sector__small-bottom"><img
                  :src="sectors[sector][2].mainImage || sectors[sector][2].coverImage || defaultImage.cover"/></a>
              </div>

              <h4 class="mb-0 mt-4 cursor" @click="filterBusinesses(sector, true)">
                <span>{{ sector }}</span>
              </h4>
              <p>{{ sectors[sector].length }} businesses</p>
            </div>
          </div> -->
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import ReadMore from "@/components/readmore.vue";
import Pagination from "@/components/pagination.vue";
import { getImageFromBlogPost } from "@/helpers/index.js";
export default {
  components: {
    ReadMore,
    Pagination,
  },
  created() {
    this.getAllBusinesses();
  },
  data() {
    return {
      DOMAIN_URL: "https://www.zone-houthalen-helchteren.be/",
      BLOG_SOURCE: "https://zone-houthalen-helchteren.blogspot.com/",
      loaded: false,
      searchLoaded: false,
      type: "home",
      searchField: {
        business: "",
        news: "",
        jobs: "",
        events: "",
      },
      searchResult: {
        business: [],
        news: [],
        jobs: [],
        events: [],
      },
      finalSearchResult: [],
      zones: [],
      news: [],
      jobs: [],
      events: [],
      // zonesPick: [],
      zoneMembers: [],
      zonesRandom: [],
      sectors: {},
      companyLists: [],
      pageOfItems: [],
      defaultImage: {
        cover:
          "https://1.bp.blogspot.com/-8MFatybaSXo/YBL1-G3qybI/AAAAAAAACQw/AD2EHCGPzZk-XsDZ9YmjdHtVlvvd9KbWQCLcBGAsYHQ/s1200/bg-mechelen-noord.jpg",
        favicon:
          "https://uptodatewebdesign.s3.eu-west-3.amazonaws.com/uploads/photos-1618802110694.png",
      },
    };
  },
  methods: {
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },

    getResultValuePost(result) {
      return result.title.$t;
    },
    getResultValue(result) {
      return result.name;
    },
    renderSearchResult(type, payload) {
      this.type = "search";
      if (type === "business" && payload) {
        this.finalSearchResult = payload;
      } else if (type === "post" && payload) {
        this.finalSearchResult = payload;
      } else if (!payload) {
        this.finalSearchResult = [];
      }
      this.searchLoaded = true;
    },
    filterBusinesses(sector, toScrollUp) {
      if (toScrollUp) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
      this.renderSearchResult("business", this.sectors[sector]);
    },
    handleSubmit(result) {
      console.log('handle submit:', result)
      if (result) {
        window.location.href = `${this.DOMAIN_URL}bedrijven/${result.slug}`;
      } else {
        this.renderSearchResult("business", this.searchResult.business);
      }
    },
    renderImageFromBlogPost(content) {
      return getImageFromBlogPost(content);
    },
    handlePost(result, objTarget) {
      this.renderSearchResult("post", this.searchResult[objTarget]);
      this.type = "search-post";
      if (result) {
        let link = result.link.filter((l) => l.rel == "alternate")[0].href;
        link = link.split(this.BLOG_SOURCE);
        window.location.href = `${this.DOMAIN_URL}${link[1]}?id=${
          result.id.$t.split(".")[2].split("post-")[1]
        }`;
      }
    },
    handleSubmitNews(result) {
      this.handlePost(result, "news");
    },
    handleSubmitEvents(result) {
      this.handlePost(result, "events");
    },
    handleSubmitJobs(result) {
      this.handlePost(result, "jobs");
    },
    readPost(result) {
      let link = result.link.filter((l) => l.rel == "alternate")[0].href;
      link = link.split(this.BLOG_SOURCE);
      return `${this.DOMAIN_URL}${link[1]}?id=${
        result.id.$t.split(".")[2].split("post-")[1]
      }`;
    },
    search(input) {
      this.searchField.business = input;
      this.searchResult.business =
        this.zones.filter((zonesItem) => {
          return zonesItem.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }) || [];
      return this.searchResult.business;
    },
    async searchNews(input) {
      if (this.news.length == 0) {
        this.news = await this.apiGetNews();
      }
      this.searchResult.news =
        this.news.filter((post) => {
          return post.title.$t.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }) || [];
      return this.searchResult.news;
    },
    async apiGetNews() {
      try {
        return await new Promise((resolve, reject) => {
          let url = `${this.DOMAIN_URL}api/blog`;
          var xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
          xmlhttp.open("POST", url);
          xmlhttp.setRequestHeader(
            "Content-Type",
            "application/json;charset=UTF-8"
          );
          xmlhttp.onreadystatechange = function () {
            if (this.readyState === XMLHttpRequest.DONE) {
              let result = JSON.parse(this.responseText);
              if (this.status === 200) {
                let result = JSON.parse(this.responseText);
                resolve(result);
                this.news = result;
                // this.loaded = true;
              } else {
                console.log(this.status, this.statusText);
              }
            }
          };
          xmlhttp.send(
            JSON.stringify({
              label: "nieuws",
              keyword: this.searchField.news,
            })
          );
        });
      } catch (err) {
        console.log(err);
      }
    },
    async searchJobs(input) {
      this.searchField.jobs = input;
      if (this.jobs.length == 0) {
        this.jobs = await this.apiGetJobs();
      }

      this.searchResult.jobs =
        this.jobs.filter((post) => {
          return post.title.$t.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }) || [];
      return this.searchResult.jobs;
    },
    async apiGetJobs() {
      return await new Promise((resolve, reject) => {
        let url = `${this.DOMAIN_URL}api/blog`;
        var xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
        xmlhttp.open("POST", url);
        xmlhttp.setRequestHeader(
          "Content-Type",
          "application/json;charset=UTF-8"
        );
        xmlhttp.onreadystatechange = function () {
          if (this.readyState === XMLHttpRequest.DONE) {
            let result = JSON.parse(this.responseText);
            if (this.status === 200) {
              let result = JSON.parse(this.responseText);
              resolve(result);
              this.jobs = result;
              // this.loaded = true;
            } else {
              console.log(this.status, this.statusText);
            }
          }
        };
        xmlhttp.send(
          JSON.stringify({
            label: "jobs",
            keyword: this.searchField.jobs,
          })
        );
      });
    },
    async searchEvents(input) {
      this.searchField.events = input;
      if (this.events.length === 0) {
        this.events = await this.apiGetEvents();
      }

      this.searchResult.events =
        this.events.filter((post) => {
          return post.title.$t.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }) || [];
      return this.searchResult.events;
    },
    async apiGetEvents() {
      return await new Promise((resolve, reject) => {
        let url = `${this.DOMAIN_URL}api/blog`;
        var xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
        xmlhttp.open("POST", url);
        xmlhttp.setRequestHeader(
          "Content-Type",
          "application/json;charset=UTF-8"
        );
        xmlhttp.onreadystatechange = function () {
          if (this.readyState === XMLHttpRequest.DONE) {
            let result = JSON.parse(this.responseText);
            if (this.status === 200) {
              let result = JSON.parse(this.responseText);
              resolve(result);
              this.events = result;
              // this.loaded = true;
            } else {
              console.log(this.status, this.statusText);
            }
          }
        };
        xmlhttp.send(
          JSON.stringify({
            label: "events",
            keyword: this.searchField.events,
          })
        );
      });
    },

    shuffleArray(array) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    },
    getListByName() {},
    getAllBusinesses() {
      var self = this;
      var xhr = new XMLHttpRequest();
      var url = `https://www.uptodateconnect.com/api/v1/account/company/zones-sector?id=1`;
      xhr.open("GET", url, false);
      xhr.onreadystatechange = function () {
        if (this.readyState === XMLHttpRequest.DONE) {
          if (this.status === 200) {
            self.zones = JSON.parse(this.responseText).payload;
            // self.zonesPick = self.zonesRandom.slice(0, 8);
            self.companyLists = self.zones

            self.companyLists.sort(() => {
              return 
            })

            self.zones.map((zoneItem) => {
              if (zoneItem.isZoneMember) {
                self.zoneMembers.push(zoneItem);
              }
            });
            self.zonesRandom = self.shuffleArray(self.zones);
            setInterval(() => {
              let pick;
              while (pick.length != 6) {
                let start =
                  Math.floor(Math.random() * self.zonesRandom.length) + 1;
                pick = self.zonesRandom.slice(start, start + 6);
              }
              self.zoneMembers = pick;
            }, 15000);
            this.loaded = true;
          } else {
            console.log(this.status, this.statusText);
          }
        }
      };
      xhr.send();
    },
  },
};
</script>

<style>
:root {
  --theme: #15303d;
  --theme-hover: #9e1f31;
}

/* .site-content {
  font-family: Circular, Helvetica, Arial, sans-serif;
} */

.site-content img {
  max-width: 100%;
}

.zones-staff-pick {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.zones-staff-pick div {
  max-width: 100%;
}

.zones-staff-pick img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.zones-staff-pick__item {
  width: 100%;
  position: relative;
}

.zones-staff-pick__item:hover,
.grid-explore-item img:hover,
.zones-sector img:hover {
  opacity: 0.9;
}

.zones-staff-pick__sector {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  font-weight: bold;
}

.zones-staff-pick__name {
  margin-top: 20px;
}

/*---title---*/

.zone-title {
  font-size: 46px;
  font-weight: 100;
  color: #484848;
}

.zone-title span {
  font-weight: 800;
  color: var(--theme-hover);
}

/*---gallery-prices---*/

.prices {
  margin-top: 50px;
}

.img {
  display: inline-block;
  margin: 30px 5px 0 5px;
}

.img:last-child {
  margin-right: 0;
}

.img img {
  width: 301px;
  height: 206px;
  object-fit: cover;
}

.price-stamp {
  color: #fff;
  font-weight: 900;
  font-size: 16px;
  text-shadow: 0 0 6px #615e61;
}

.description {
  font-size: 14px;
  color: #484848;
}

/*---line---*/

hr {
  border: 0;
  height: 1px;
  background: #dce0e0;
  margin-bottom: 55px;
}

/*---gallery-places---*/

.gallery-places {
  margin-bottom: 20px;
}

.places {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: block;
}

/*---gallery-guidebooks---*/

.guidebooks {
  display: block;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.guidebooks h3 {
  font-weight: 100;
  font-size: 25px;
  color: #484848;
}

.img-box {
  float: left;
  /* width: 305px; */
  height: 205px;
  /* margin: 40px 5px 0 5px; */
}

.img-box img {
  object-fit: cover;
}

.img-box:last-child {
  margin-right: 0;
}

.img-box h4 {
  font-size: 14px;
  color: #484848;
  margin: 0;
}

.img-box h4 span {
  margin-left: -1px;
}

.img-box p {
  font-size: 14px;
  color: #484848;
}

.img-big {
  float: left;
  margin-top: 3px;
  width: 200px;
  height: 163px;
}

.img-small {
  float: right;
  width: 100px;
  height: 80px;
  margin-top: 3px;
}

.gallery-places h3,
.zone-subheader {
  font-weight: 100;
  font-size: 25px;
  color: #484848;
  margin: 0 0 35px 0;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.search-cursor-tab {
  cursor: pointer;
  background-color: var(--theme);
  color: #dedbdb;
  border-radius: 0;
  font-size: 18px;
  padding: 12px 20px;
  font-family: "Lato", san-serif;
  margin-right: 10px;
  font-family: Lato, sans-serif;
}

.search-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-input-field,
.autocomplete-input {
  height: 60px;
  margin: 0 20px 0 0;
  font-size: 22px !important;
  border-radius: 0 !important;
  border: 1px solid #dedede;
  background-color: #fcfcfc !important;
}

.active.search-cursor-tab {
  background-color: var(--theme-hover) !important;
  border-color: var(--theme-hover);
  color: white !important;
}

.search-main-container div {
  width: 100%;
  margin-right: 20px;
  z-index: 10;
}

.autocomplete-result-item {
  display: flex;
}

.autocomplete-result-item img {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  object-fit: contain;
  border: 1px solid #ddd;
  padding: 5px;
}

.autocomplete-result-item-big img {
  width: 60px;
  min-width: 60px;
  height: 60px;
  object-fit: cover;
}

.autocomplete-result-item-big h4 {
  display: flex;
  max-width: 80%;
}

.zones-placeholder {
  margin-bottom: 20px;
}

.zones-placeholder-container {
  border: 1px solid #eee;
  padding: 20px;
  background: white;
  margin-top: 20px;
}

.btn,
.utd-btn {
  border-radius: 0;
}

.zones-result-container {
  display: flex;
  padding-top: 20px !important;
  border-bottom: 1px solid #eee;
}

.zones-search-result__image {
  margin-right: 30px;
  /* padding: 5px; */
}

.zones-search-result__image img {
  width: 150px;
  height: 150px;
  /* margin-right: 20px; */
  object-fit: contain;
  border: 1px solid #ddd;
  padding: 5px;
}

.autocomplete-result {
  cursor: pointer;
}

.zones-search-result__description {
  width: 100%;
}

.zones-search-result__description span {
  /* color: #aaa; */
  margin-bottom: 10px;
}

.text-link {
  cursor: pointer;
  font-family: "Lato", san-serif;
}

@media screen and (max-width: 768px) {
  .search-btn {
    display: none;
  }

  .search-main-container {
    flex-direction: column;
  }

  .zones-nav .nav-item {
    margin-bottom: 10px;
  }

  .zones-search-result__description {
    margin-top: 20px;
  }

  .search-main-container div {
    margin: 0;
  }

  .search-cursor-tab {
    font-size: 14px;
    padding: 10px 15px;
  }

  .zone-title {
    text-align: center;
  }

  .search-input-field,
  .autocomplete-input {
    font-size: 16px !important;
  }

  .autocomplete-result-item-big img {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
}

.zones-sector-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.zones-sector {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 5px;
}

.zones-sector-container h4 {
  color: var(--theme);
  font-family: "Manuale", san-serif;
}

.zones-sector img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.zones-sector__big {
  grid-area: 1 / 1 / 3 / 2;
  height: 240px;
  width: 100%;
}

.zones-sector__small-top {
  grid-area: 1 / 2 / 2 / 3;
  height: 118px;
  width: 100%;
}

.zones-sector__small-bottom {
  grid-area: 2 / 2 / 3 / 3;
  height: 118px;
  width: 100%;
}

.grid-explore {
  display: grid;
  grid-gap: 20px;
}

.grid-explore-item {
  position: relative;
  border: 1px solid #ccc;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.grid-explore-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .zones-sector-container,
  .zones-staff-pick {
    display: flex;
    flex-wrap: wrap;
  }

  .zones-result-container {
    display: unset;
  }

  .zones-staff-pick a,
  .zones-staff-pick div {
    width: 100%;
  }

  .search-btn {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid-explore {
    grid-template-columns: repeat(9, 1fr);
  }

  .grid-explore-item {
    grid-column: span 3;
    grid-template-rows: 1fr;
  }
}

@media only screen and (min-width: 992px) {
  .grid-explore {
    grid-template-columns: repeat(12, 1fr);
  }

  .grid-explore-item {
    grid-column: span 3;
    grid-template-rows: 1fr;
  }
}

.grid-search-item {
  border: 1px solid;
}

@media only screen and (min-width: 768px) {
  .grid-search {
    grid-template-columns: repeat(8, 1fr);
  }

  .grid-search-item {
    grid-column: span 4;
    grid-template-rows: 1fr;
  }

  .grid-search-item:nth-child(1) {
    grid-column: span 8;
    grid-row: span 2;
  }
}

@media only screen and (min-width: 992px) {
  .grid-search {
    grid-template-columns: repeat(12, 1fr);
  }

  .grid-search-item {
    grid-column: span 3;
    grid-template-rows: 1fr;
  }

  .grid-search-item:nth-child(1) {
    grid-column: span 9;
    grid-row: span 2;
  }
}

.headline h2:before {
  content: "";
  width: 3vw;
  height: 4px;
  background: var(--theme-hover);
  position: absolute;
  bottom: -12px;
  left: 0;
}

.secondary-header {
  position: relative;
}

.btn__custom {
  border: 1px solid;
  padding: 10px;
  font-weight: bolder;
  display: block;
  border: 1px solid var(--theme-hover);
  color: var(--theme-hover);
}

.btn__custom:hover {
  background: var(--theme-hover);
  color: white;
}

.place-names {
  margin-bottom: 20px;
  margin-top: 0;
  color: var(--theme);
  font-family: Manuale, san-serif;
  font-weight: normal;
}

.search-btn {
  background: var(--theme-hover);
}

.td-title {
  width: 20%;
}
.badge-info-custom {
  border: 1px solid #e7e7e7;
  padding: 5px;
  color: #aaa;
}
.table,
.table a {
  /* color: var(--body-font-color); */
}
.pagination .page-item.active .page-link {
    color: white;
    background: var(--theme-hover);
    border: none;
}
.pagination .page-link {
  color: var(--theme);
}
iframe {
  border: none;
}
.autocomplete-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #899297;
  opacity: 1; /* Firefox */
}

.autocomplete-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #899297;
}

.autocomplete-input::-ms-input-placeholder { /* Microsoft Edge */
  color: #899297;
}
</style>